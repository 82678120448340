<template>
  <v-dialog v-model="dialog" persistent max-width="768px">
    <!--dialog-->
    <v-card>
      <v-card-title class="primary text--white">
        <span class="text-h5 white--text">New Employee {{ mod_state }}</span>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-container>
            <v-card-actions class="pa-0 mb-2">
              <v-btn
                rounded
                color="secondary"
                class="casual_btn"
                @click.prevent="save_added_emp"
              >
                <v-icon left> $save </v-icon>
                {{ btn_save_label }}
              </v-btn>
              <v-btn
                rounded
                color="secondary"
                class="ml-2 casual_btn"
                @click.prevent="close_dialog"
              >
                <v-icon left> $close </v-icon>
                Cancel
              </v-btn>
            </v-card-actions>
            <!-- <h3>Hiring Authority(HA) Section</h3> -->
            <v-row>
              <v-col cols="12" md="12">
                <h3>HIRING AUTHORITY(HA) SECTION</h3>
                <hr />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="loc_new_emp.ha_fullname"
                  :rules="nameRules"
                  :counter="60"
                  maxlength="60"
                  label="(HA) Full Name"
                  placeholder="John Smith"
                  required
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="loc_new_emp.hire_auth_email"
                  :rules="emailRules_hire"
                  type="text"
                  :counter="120"
                  maxlength="120"
                  placeholder="email@reno.gov"
                  label="(HA) Email"
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" md="4">
                <v-text-field
                  v-model="loc_new_emp.ha_phone"
                  :counter="12"
                  maxlength="12"
                  v-mask="'###-###-####'"
                  label="(HA) Phone"
                  placeholder="123-123-1234"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="4">
                <v-select
                  :items="div_names"
                  :counter="60"
                  v-model="loc_new_emp.ha_div"
                  label="(HA) Division"
                ></v-select>
              </v-col>
              <v-col cols="12" md="4">
                <v-select
                  :items="div_names"
                  :counter="60"
                  v-model="loc_new_emp.ha_unit"
                  label="(HA) Unit"
                ></v-select>
              </v-col>
            </v-row>
            <!-- <h3>Employee Section</h3> -->
            <v-row>
              <v-col cols="12" md="12">
                <h3>EMPLOYEE SECTION</h3>
                <hr />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="loc_new_emp.fname"
                  :rules="nameRules"
                  :counter="60"
                  maxlength="60"
                  label="First name"
                  required
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" md="4">
                <v-text-field
                  v-model="loc_new_emp.lname"
                  :rules="nameRules"
                  :counter="60"
                  maxlength="60"
                  label="Last name"
                  required
                >
                </v-text-field>
              </v-col>

              <!-- <v-col cols="12" md="4">
                <v-text-field
                  v-model="loc_new_emp.dob"
                  type="date"
                  :rules="dobRules"
                  :counter="10"
                  label="DOB"
                  min="1922-01-01"
                  required
                >
                </v-text-field>
              </v-col> -->
              <v-col cols="12" md="4">
                <v-select
                  :items="emp_types"
                  :counter="60"
                  maxlength="60"
                  v-model="loc_new_emp.emp_type"
                  label="Emp. Type"
                ></v-select>
              </v-col>
              <v-col cols="12" md="4">
                <v-select
                  :items="units"
                  :counter="60"
                  maxlength="60"
                  v-model="loc_new_emp.unit"
                  label="Unit"
                ></v-select>
              </v-col>

              <v-col cols="12" md="4">
                <v-select
                  :items="div_names"
                  :counter="60"
                  v-model="loc_new_emp.division"
                  label="Division"
                ></v-select>
              </v-col>

              <v-col cols="12" md="4">
                <v-text-field
                  v-model="loc_new_emp.supervisor"
                  :counter="120"
                  maxlength="120"
                  :rules="name120"
                  label="Supervisor"
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" md="4">
                <v-text-field
                  v-model="loc_new_emp.persons_cell"
                  :counter="12"
                  maxlength="12"
                  v-mask="'###-###-####'"
                  label="Personal Cell"
                  placeholder="123-123-1234"
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" md="4">
                <v-text-field
                  v-model="loc_new_emp.work_cell"
                  :counter="12"
                  maxlength="12"
                  v-mask="'###-###-####'"
                  label="Work Cell"
                  placeholder="123-123-1234"
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" md="4">
                <v-text-field
                  v-model="loc_new_emp.work_email"
                  :rules="emailRules"
                  :counter="120"
                  maxlength="120"
                  label="Work Email"
                  placeholder="email@reno.gov"
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" md="4">
                <v-text-field
                  v-model="loc_new_emp.personal_email"
                  @focus.prevent="pers_email_focus"
                  :counter="120"
                  maxlength="120"
                  :rules="emailRules_pers"
                  label="Personal Email"
                  placeholder="email@domain.com"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="loc_new_emp.job_title"
                  :counter="60"
                  maxlength="60"
                  label="Job Title"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="loc_new_emp.st_date"
                  type="date"
                  :counter="10"
                  maxlength="10"
                  label="Start Date"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12">
                <h3>ADMIN ONLY SECTION</h3>
                <hr />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="loc_new_emp.finger_print_num"
                  type="text"
                  :counter="14"
                  maxlength="14"
                  label="TCN#"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="loc_new_emp.access_card_number"
                  type="text"
                  :counter="7"
                  maxlength="7"
                  label="Access Card#"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="loc_new_emp.badge_emp_num"
                  type="text"
                  :counter="25"
                  maxlength="25"
                  label="Badge/Emp#:"
                >
                </v-text-field>
              </v-col>

              <v-container fluid>
                <v-textarea
                  :counter="8000"
                  label="Notes (i.e. Clearances needed, Delays, Msc):"
                  v-model="loc_new_emp.notes"
                ></v-textarea>
              </v-container>
            </v-row>
            <v-card-actions>
              <v-btn
                rounded
                color="secondary"
                class="casual_btn"
                @click.prevent="save_added_emp"
              >
                <v-icon left> $save </v-icon>
                {{ btn_save_label }}
              </v-btn>
              <v-btn
                rounded
                color="secondary"
                class="ml-2 casual_btn"
                @click.prevent="close_dialog"
              >
                <v-icon left> $close </v-icon>
                Cancel
              </v-btn>
            </v-card-actions>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<style lang="scss" scoped>
@import "../scss/site.scss";
.casual_btn {
  width: 115px;
}
label {
  font-weight: 500;
}
</style>

<script>
import { nextTick } from "vue";
/* eslint-disable */
import { bus_common } from "../mixins/bus_common";
import { mask } from "vue-the-mask";

export default {
  name: "Addedit_diag",
  mixins: [bus_common],
  props: ["mod_state", "dialog", "new_emp", "per_email_warning", "emp_types"],
  directives: { mask },
  data() {
    return {
      valid: false,
      loc_new_emp: {
        ha_unit: "",
        ha_div: "",
        ha_fullname: "Johntest Molt",
        ha_phone: "",
        fname: "John",
        lname: "Molt",
        dob: "1/1/0001",
        division: "",
        unit: "",
        st_date: "",
        divsion: "",
        supervisor: "",
        persons_cell: "",
        work_cell: "",
        personal_email: "",
        work_email: "",
        finger_print_num: "",
        hire_auth_email: "jvonmolt@gmail.com",
        badge_emp_num: "",
        emp_type: "SWORN",
      },

      emailRules: [
        (v) =>
          !v || /.+@.+\..+/.test(v) || "Email must be of a valid email format",
        (v) => v.length <= 120 || "This field must be less than 120 characters",
      ],
      emailRules_pers: [
        (v) =>
          !v || /.+@.+\..+/.test(v) || "Email must be of a valid email format",
        (v) => v.length <= 120 || "This field must be less than 120 characters",
        (v) =>
          v.indexOf(".gov") == -1 ||
          "This email must NOT be of City/Gov issue. ",
      ],
      emailRules_hire: [
        (v) => !!v || "This field  is required",
        (v) =>
          !v || /.+@.+\..+/.test(v) || "Email must be of a valid email format",
        (v) => v.length <= 120 || "This field must be less than 120 characters",
      ],
      nameRules: [
        (v) => !!v || "This field  is required",
        (v) => v.length <= 60 || "This field must be less than 60 characters",
      ],
      dobRules: [
        (v) => !!v || "This field  is required",
        (v) =>
          new Date(v) >= new Date("1/1/1922") || "DOB must be more recent.",
        (v) => new Date(v) <= this.now18yo || "DOB must be more older.",
      ],
      name120: [
        (v) =>
          v.length <= 120 || "This field  must be less than 120 characters",
      ],

      email: "",
      units: [
        "Victim Services",
        "Sex Crimes",
        "Robbery/Homicide",
        "Financial/Computer Crimes",
        "Burglary",
        "Family Crimes",
        "Records",
        "Traffic",
      ],
      div_names: [
        "Accounting",
        "Admin Clerical Support",
        "Admin Services Manager",
        "Auto Theft",
        "Backgrounds",
        "Backgrounds ATL",
        "DET Cell Phone (Bike Team)",
        "Burglary",
        "Business License Processing",
        "Child Abuse",
        "Community Action Office (CAO)",
        "Crime Analysis",
        "Detectives Main",
        "Dispatch Non Emergency 334-COPS",
        "Family Crimes",
        "Finance",
        "Financial Crimes/Fraud",
        "Front Desk Reception",
        "Northern Nev. Regional Intelligence Cente",
        "Graffiti (Reno Direct)",
        "Human Resources",
        "Internal Affairs",
        "Missing Persons Unit",
        "PIO (Public Information Officer)",
        "Records and ID",
        "RGU (Regional Gang Unit)",
        "RHU (Robbery/Homicide Unit)",
        "Ride-Along Program",
        "Regional Sex Offender Notification",
        "S.A.V.E.",
        "Sex Crimes",
        "South Sub Station",
        "Traffic",
        "Traffic Office / Investigation",
        "Work Cards",
        "Recruitment",
        "Secret Witness",
        "Street Enforcement Team (S.E.T.)",
        "Victim Services",
        "Property and Evidence",
        "Police Training Office",
        "K-9",
        "Community Outreach",
        "Grants",
        "LGBTQ Police Liaison",
      ],
    };
  },
  computed: {
    btn_save_label() {
      return this.mod_state == "ADD" ? "Submit" : "Save";
    },
    now18yo() {
      var adate = new Date();
      adate.setFullYear(adate.getFullYear() - 17);
      return adate;
    },
  },
  methods: {
    message1() {
      alert("This button does nothing yet.");
    },
    pers_email_focus() {
      if (this.per_email_warning == false) {
        this.swall_it(
          "Caution",
          "Only use the Personal Email field if the City Issued email" +
            " is NOT available and a personal email is needed.",
          "warning",
          null,
          null
        );
        this.$emit("per_email_warning_now");
      }
    },

    save_added_emp() {
      if (this.$refs.form.validate() == false) {
        this.swall_it(
          "Some Correction Needed",
          "Please correct the Red entry errors.",
          "warning",
          null,
          null
        );
        return true;
      }
      this.$emit("save_added_emp", this.loc_new_emp);
    },
    close_dialog() {
      this.$emit("close_dialog");
    },
    init() {
      this.loc_new_emp = this.mno(this.new_emp);
      this.units.sort();
      this.units.unshift("Other");
      this.units.unshift("Not Applicable");
      this.div_names.sort();
      this.div_names.unshift("Other");
      this.div_names.unshift("Not Applicable");
      //test_loc_new_emp
      //this.loc_new_emp = this.mno(this.test_loc_new_emp);
      // if (this.mod_state !="ADD") {
      //   this.loc_new_emp.ha_fullname = "Paul Bunyan";
      //   this.loc_new_emp.hire_auth_email = "test@test.com";
      // }

      this.$refs.form.resetValidation();
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.init();
    });
  },
};
</script>
